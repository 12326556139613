import React, { Fragment } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { createStyles } from "@material-ui/core"
// import Link from "@material-ui/core/Link"
import { scrollTo } from "../../var/util"
import { Link } from "gatsby"

const useStyles = makeStyles((theme) => createStyles({
  menuLink: {
    marginRight: theme.spacing(2),
    padding: theme.spacing(1),
    color: theme.palette.primary.contrastText,
    textDecoration: "none",
    fontSize: 16,
    "&:hover": {
      textDecoration: "underline",
    },
  },
}))
const MenuLinks = () => {
  const classes = useStyles()
  const handleClick = (target, event) => {
    if (scrollTo(target)) {
      event.preventDefault()
    }
  }
  const links = [
    {
      href: "/#features",
      title: "Features",
    },
    {
      href: "/about",
      title: "About",
    },
    {
      href: "/how-to",
      title: "Help",
    },
    // {
    //   href: "/#pricing",
    //   title: "Pricing",
    // },
    {
      href: "/contact",
      title: "Contact",
    },
  ]
  return (
    <Fragment>
      {links.map(link =>
        <Link key={link.href} to={link.href}
              onClick={(event) => handleClick(link.href, event)}
              className={classes.menuLink}>{link.title}</Link>,
      )}
    </Fragment>
  )
}

export default MenuLinks

import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { createStyles } from "@material-ui/core"
import Snackbar from "@material-ui/core/Snackbar"
import { Link } from "gatsby"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/CloseRounded"
import Alert from "@material-ui/lab/Alert"


const useStyles = makeStyles((theme) => createStyles({
  root: {
    // width: "100%",
    // "& > * + *": {
    //   marginTop: theme.spacing(2),
    // },
  },
  content: {
    margin: 0,
  },
  message: {
    padding: 0,
    margin: "auto 0",
  },
  link: {
    color: "inherit",
  },
  closeIcon: {
    color: "inherit",
  },
  snackbar: {
    [theme.breakpoints.down("sm")]: {
      bottom: 65,
    },
    transition: "all 0.5s ease",
  },
}))


const CookieConsent = () => {
  const classes = useStyles()

  const [open, setOpen] = React.useState(parseInt(localStorage.getItem("cookie_consent")) !== 0)

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    localStorage.setItem("cookie_consent", 0)
    setOpen(false)
  }
  return (

    <Snackbar open={open}
              className={classes.snackbar}
              ContentProps={
                {
                  classes: {
                    root: classes.message,
                    message: classes.message,
                  },
                  className: classes.content,
                }
              }
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              onClose={handleClose}
              message={
                <Alert severity="info"
                       action={
                         <IconButton size={"small"}
                                     className={classes.closeIcon}
                                     onClick={handleClose}><CloseIcon/></IconButton>
                       }>
                  We use cookies to provide and improve our services. By using our site, you consent to cookies. <Link
                  className={classes.link}
                  to={"/data-use"}>Learn more</Link>.
                </Alert>}
    />
  )
}

export default CookieConsent

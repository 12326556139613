import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { createStyles } from "@material-ui/core"
import { Link } from "gatsby"
import { scrollTo } from "../../var/util"
import Grid from "@material-ui/core/Grid"


const useStyles = makeStyles((theme) => createStyles({
  menuLink: {
    marginRight: theme.spacing(2),
    padding: theme.spacing(1),
    color: theme.palette.primary.contrastText,
    textDecoration: "none",
    fontSize: 16,
    "&:hover": {
      textDecoration: "underline",
    },
  },
}))
const FooterLinks = () => {
  const classes = useStyles()
  const handleClick = (target, event) => {
    if (scrollTo(target, "center")) {
      event.preventDefault()
    }
  }
  const links = [
    {
      href: "/#features",
      title: "Features",
    },
    // {
    //   href: "/#pricing",
    //   title: "Pricing",
    // },
    {
      href: "/terms",
      title: "Terms and Conditions",
    },
    {
      href: "/data-use",
      title: "Privacy",
    },
    {
      href: "/contact",
      title: "Contact",
    },
  ]
  return (
    <Grid container justify={"center"} spacing={1}>
      {links.map(link =>
        <Grid item key={link.href}>
          <Link to={link.href}
                onClick={(event) => handleClick(link.href, event)}
                className={classes.menuLink}>{link.title}</Link>
        </Grid>,
      )}
    </Grid>
  )
}

export default FooterLinks

import React, { Fragment } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Container, createStyles } from "@material-ui/core"
import Link from "@material-ui/core/Link"
import Typography from "@material-ui/core/Typography"
import Support from "./Support"
import Box from "@material-ui/core/Box"
import FooterLinks from "./FooterLinks"
import CookieConsent from "./CookieConsent"

const useStyles = makeStyles((theme) => createStyles({
  main: {
    marginTop: theme.spacing(2),
    padding: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px`,
    color: theme.palette.primary.contrastText,
    position: "relative",
    background: theme.custom.fancyBackground,
  },
  links: {
    textAlign: "center",
  },
}))

const Footer = ({ excludeSupport }) => {
  const classes = useStyles()
  const year = new Date().getFullYear()
  return (
    <Fragment>
      <section id={"footer"} className={classes.main}>
        <Container maxWidth={"lg"} className={classes.content}>
          {!excludeSupport &&
          <Support/>
          }
          <Box className={classes.links}>
            <FooterLinks/>
          </Box>
          <Typography variant={"caption"}>© {year} <Link href={"https://datanasov.com"} color={"inherit"}
                                                         target={"_blank"}>datanasov.com</Link></Typography>
        </Container>
      </section>
      <CookieConsent/>
    </Fragment>
  )
}

export default Footer

export const scrollTo = (target, block = "start") => {
  target = trim(target, "/")
  if (target.startsWith("#") || target.startsWith(".")) {
    const anchor = document.querySelector(target)
    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: block })
      return true
    }
  }
  return false
}

export const trim = (string, char) => {
  if (char === "]") char = "\\]"
  if (char === "\\") char = "\\\\"
  return string.replace(new RegExp(
    "^[" + char + "]+|[" + char + "]+$", "g",
  ), "")
}
export const validateEmail = (email) => {
  return (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(String(email).toLowerCase())
}

export const canSync = () => false

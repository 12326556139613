import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import { createStyles, useScrollTrigger } from "@material-ui/core"
import { graphql, Link, useStaticQuery } from "gatsby"
import Container from "@material-ui/core/Container"
import Slide from "@material-ui/core/Slide"
import PropTypes from "prop-types"
import Hidden from "@material-ui/core/Hidden"
import MenuLinks from "../partials/MenuLinks"
import Avatar from "@material-ui/core/Avatar"


const useStyles = makeStyles((theme) => createStyles({
  main: {
    position: "relative",
  },
  appBar: {
    background: "transparent",
    boxShadow: "none",
    border: "none",
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  menuLink: {
    marginRight: theme.spacing(2),
    padding: theme.spacing(1),
    color: theme.palette.primary.contrastText,
  },
  headerLink: {
    textDecoration: "none",
    color: theme.palette.primary.contrastText,
  },
  icon: {
    borderRadius: 8,
  },

}))

function HideOnScroll(props) {
  const { children, window } = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined })

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
}
const Header = ({ title }) => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query {
     file(relativePath: {eq: "icons/icon.png"}) {
       childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 128) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`)
  return (
    <Container maxWidth="lg" className={classes.main}>
      <AppBar className={classes.appBar} position="absolute" variant={"outlined"} id="top-anchor" color={"primary"}>
        <Toolbar>
          <Hidden xsDown>
            <Typography variant="h6" className={classes.title}>
              <Link to={"/"} className={classes.headerLink}>
                {title}
              </Link>
            </Typography>

          </Hidden>
          <Hidden smUp>
            <Link to={"/"} className={classes.headerLink}>
              <Avatar color={"primary"} alt="Memo Reminders" src={data.file.childImageSharp.fluid.src}
                      className={classes.icon}/>
            </Link>
          </Hidden>
          <MenuLinks/>
        </Toolbar>
      </AppBar>
    </Container>
  )
}

export default Header

import { createMuiTheme } from "@material-ui/core/styles"
import primaryColor from "@material-ui/core/colors/blueGrey"
import primaryDarkColor from "@material-ui/core/colors/blueGrey"
import secondaryColor from "@material-ui/core/colors/blue"


const options = {
  palette: {
    primary: primaryColor,
    secondary: secondaryColor,
    background: {
      middle: "#fdfdfd",
      default: "#e6e5e6",
    },
  },
  custom: {
    fancyBackground: `radial-gradient( circle farthest-corner at 10% 20%,  ${primaryColor[700]} 0%, ${primaryColor[500]} 90% )`,
  },
}

export const theme = createMuiTheme(options)
export const darkTheme = createMuiTheme({
  ...options,
  palette: {
    ...options.palette,
    primary: primaryDarkColor,
    type: "dark",
    background: {
      paper: "#212121",
      middle: "#1d1d1d",
      default: "#121212",
    },
  },
})

import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Container, createStyles } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import Link from "@material-ui/core/Link"

const useStyles = makeStyles((theme) => createStyles({
  content: {
    textAlign: "center",
    marginBottom: theme.spacing(1),
  },
}))

const Support = () => {
  const classes = useStyles()
  return (
    <Container maxWidth={"md"} className={classes.content}>
      <Typography align={"center"} variant={"h6"}>If you have have questions, suggestions or you need app support,
        please send email
        to <Link color={"inherit"} href={"mailto:hello@memoreminders.com"}
                 underline={"always"}>hello@memoreminders.com</Link></Typography>
    </Container>
  )
}

export default Support
